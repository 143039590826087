import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Router } from '@angular/router';
import { CartService } from './services/cart.service';
import { ModalController, AlertController, LoadingController, MenuController } from '@ionic/angular';
import { HttpClient } from "@angular/common/http";
import { CartModalPage } from '././pages/cart-modal/cart-modal.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  cart: any;
  card: any;
  paymentAmount: any;
  loadingPayment: any;
  public paymentDisabled = true;
  
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Shop',
      url: '/shop',
      icon: 'pricetag'
    },
    /*{
      title: 'Our Story',
      url: '/about',
      icon: 'book'
    },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'mail'
    }*/
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private cartService: CartService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private menuCtrl: MenuController,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === "/" + path.toLowerCase());
    }
    this.cart = this.cartService.getCart();
  }

  async goToDetails(productid) {
    this.router.navigateByUrl('details/'+productid);
    this.menuCtrl.close();
  }
  
  closeMenu() {
    this.menuCtrl.close();
  }
  
  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
  }
 
  increaseCartItem(product) {
    if (product.amount < product.inventory) {
      this.cartService.addProduct(product, 1);
    }
  }
 
  removeCartItem(product) {
    this.cartService.removeProduct(product);
  }
 
  getTotal() {
    this.paymentAmount = this.cart.reduce((i, j) => i + j.price * j.amount, 0);
    if (this.paymentAmount > 0) {
      this.paymentDisabled = false;
    } else {
      this.paymentDisabled = true;
    }
    return this.cart.reduce((i, j) => i + j.price * j.amount, 0);
  }

  async openCart() {
    let modal = await this.modalCtrl.create({
      component: CartModalPage,
      cssClass: 'cart-modal'
    });
    modal.onWillDismiss().then((response) => {
      console.log(response);
      // this.fab.nativeElement.classList.remove('animated', 'bounceOutLeft')
      // this.animateCSS('bounceInLeft');
      // this.cartItemCount = this.cartService.getCartItemCount();
      if (response.data=='paid') {
        this.menuCtrl.close();
        this.cartService.clearCart();
        this.cart = this.cartService.getCart();
        // this.card.clear();
      }
    });
    modal.present();
  }

  async checkout() {
    // Perfom PayPal or Stripe checkout process
 
    let alert = await this.alertCtrl.create({
      header: 'Thanks for your Order!',
      message: 'We will deliver your food as soon as possible',
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.menuCtrl.toggle();
    });
  }
  
  makePayment(token) {
    this.loadingSpinner();
    
    var paymentToSend = {
      "amount": parseFloat(this.paymentAmount)*100,
      "currency": "usd",
      "token": token.id,
      "email": "jdsoohoo@gmail.com",
      "shipping_name": "John",
      "shipping_address_line1": "5000 S Slauson Ave",
      "shipping_address_line2": "333",
      "shipping_address_city": "Culver City",
      "shipping_address_state": "CA",
      "shipping_address_country": "USA",
      "shipping_address_postal_code": "90230"
    }
    // console.log(paymentToSend);
    
    this.http.post('https://us-central1-ecommerce-app-bf313.cloudfunctions.net/payWithStripe', paymentToSend).subscribe((response) => {
      if (response) {
        this.loadingPayment.dismiss();
          console.log("success");
          // console.log(response);
          this.checkoutConfirm();
      }
    },
    error => {
      this.loadingPayment.dismiss();
      // console.log(error);
      this.checkoutError();
    });
    // .subscribe(data => {
    //   if (data) {
    //     var result = JSON.parse(data["_body"]);
    //     console.log(data);
    //   }
    //   this.checkoutConfirm();
    // });
  }

  async loadingSpinner() {
    this.loadingPayment = await this.loadingCtrl.create({
      spinner: 'crescent'
    }); 
  
    this.loadingPayment.present();
  }

  async checkoutConfirm() {
    // Perfom PayPal or Stripe checkout process
 
    let alert = await this.alertCtrl.create({
      header: 'Thanks for your Order!',
      message: 'We will deliver your order as soon as possible',
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.menuCtrl.close();
      this.cartService.clearCart();
      this.cart = this.cartService.getCart();
      this.card.clear();
    });
  }
  
  async checkoutError() {
    // Perfom PayPal or Stripe checkout process
 
    let alert = await this.alertCtrl.create({
      header: 'There was an error processing your transaction.',
      message: 'Your card has not been charged.  Please try again.',
      buttons: ['OK']
    });
    alert.present().then(() => {
      // this.menuCtrl.toggle();
    });
  }
  
}
