import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
 
// export interface Product {
//   id: number;
//   name: string;
//   img: string;
//   price: number;
//   amount: number;
// }
@Injectable({
  providedIn: 'root'
})
export class CartService {
  // data: Product[] = [
  //   { id: 0, name: 'Live Dungeness Crab (Box of 20)', img: '././assets/images/live_dungeness_crab.jpg', price: 88.99, amount: 0 },
  //   { id: 1, name: 'Whole Cooked Dungeness Crab (Box of 8)', img: '././assets/images/cooked_dungeness_crab.jpg', price: 100.49, amount: 0 },
  //   { id: 2, name: 'Live Spot Prawns (10 pound box)', img: '././assets/images/live_spot_prawns.jpg', price: 200, amount: 0 },
  //   { id: 3, name: 'Wild Black Cod (25 pound box)', img: '././assets/images/black_cod.jpg', price: 220, amount: 0 },
  //   { id: 4, name: 'Sockeye Salmon (25 pound box)', img: '././assets/images/sockeye_salmon.jpg', price: 200, amount: 0 },
  //   { id: 5, name: 'Wild Pacific Halibut (25 pound box)', img: '././assets/images/pacific_halibut.jpg', price: 300, amount: 0 }
  // ];
 
  private cart = [];
  private cartItemCount = new BehaviorSubject(0);
 
  constructor() {}
 
  // getProducts() {
  //   return this.data;
  // }

  // getProduct(productid) {
  //   for (let p of this.data) {
  //     if (p.id == productid) {
  //       return p;
  //     }
  //   }
  // }
 
  getCart() {
    return this.cart;
  }
  
  clearCart() {
    this.cart = [];
    this.cartItemCount.next(0);
  }
 
  getCartItemCount() {
    return this.cartItemCount;
  }
 
  addProduct(product, amt) {
    let added = false;
    for (let p of this.cart) {
      if (p.id === product.id) {
        if (p.amount + amt <= p.inventory) {
          p.amount += amt;
          this.cartItemCount.next(this.cartItemCount.value + amt);
        }
        added = true;
        break;
      }
    }
    if (!added) {
      product.amount = amt;
      this.cart.push(product);
      this.cartItemCount.next(this.cartItemCount.value + amt);
    }
  }
 
  decreaseProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === product.id) {
        p.amount -= 1;
        if (p.amount == 0) {
          this.cart.splice(index, 1);
        }
      }
    }
    this.cartItemCount.next(this.cartItemCount.value - 1);
  }
 
  removeProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id === product.id) {
        this.cartItemCount.next(this.cartItemCount.value - p.amount);
        this.cart.splice(index, 1);
      }
    }
  }
}