import { CartService } from './../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
declare var Stripe;
import { HttpClient } from "@angular/common/http";
 
@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.page.html',
  styleUrls: ['./cart-modal.page.scss'],
})
export class CartModalPage implements OnInit {

  cart: any;
  // stripe = Stripe('pk_live_RbVR6QAIOw22Fs8xNTAPV7p800ZYXxQAGm');
  stripe = Stripe('pk_test_xa79ta2H7gm9PeJEWdivdTqG00NRUbYVs2');
  card: any;
  paymentAmount: any;
  orderDescription: any;
  customer_name: any;
  customer_email: any;
  shipping_address_line1: any;
  shipping_address_line2: any;
  shipping_address_city: any;
  shipping_address_state: any;
  shipping_address_country: any;
  shipping_address_postal_code: any;
  loadingPayment: any;
  public paymentDisabled = true;
 
  constructor(private cartService: CartService, private modalCtrl: ModalController, private alertCtrl: AlertController, private http: HttpClient, private loadingCtrl: LoadingController) { }
 
  ngOnInit() {
    this.cart = this.cartService.getCart();
    this.setupStripe();
  }

  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
  }
 
  increaseCartItem(product) {
    this.cartService.addProduct(product, 1);
  }
 
  removeCartItem(product) {
    this.cartService.removeProduct(product);
  }
 
  getTotal() {
    this.paymentAmount = this.cart.reduce((i, j) => i + j.price * j.amount, 0);
    if (this.paymentAmount > 0 && this.shipping_address_line1 && this.customer_email && this.customer_name) {
      this.paymentDisabled = false;
    } else {
      this.paymentDisabled = true;
    }
    return this.cart.reduce((i, j) => i + j.price * j.amount, 0);
  }
 
  close() {
    this.modalCtrl.dismiss();
  }
 
  async checkout() {
    // Perfom PayPal or Stripe checkout process
 
    let alert = await this.alertCtrl.create({
      header: 'Thanks for your Order!',
      message: 'We will deliver your food as soon as possible',
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.modalCtrl.dismiss();
    });
  }
  
  //Stripe Code Here
  setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style: style });

    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();
      console.log(event)

      this.stripe.createSource(this.card).then(result => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // console.log(result);
          this.makePayment(result.source);
        }
      });
    });
  }
  
  makePayment(token) {
    this.loadingSpinner();
    
    this.orderDescription = "";
    this.cart.forEach(item => {
      this.orderDescription = this.orderDescription.concat(item.amount," x ",item.name," $",item.price," each | ");
    });
    var paymentToSend = {
      "amount": parseFloat(this.paymentAmount)*100,
      "currency": "usd",
      "token": token.id,
      "order_description": this.orderDescription,
      "email": this.customer_email,
      "shipping_name": this.customer_name,
      "shipping_address_line1": this.shipping_address_line1,
      "shipping_address_line2": this.shipping_address_line2,
      "shipping_address_city": this.shipping_address_city,
      "shipping_address_state": this.shipping_address_state,
      "shipping_address_country": this.shipping_address_country,
      "shipping_address_postal_code": this.shipping_address_postal_code
    }
    // console.log(paymentToSend);
    
    this.http.post('https://us-central1-ecommerce-app-bf313.cloudfunctions.net/payWithStripe', paymentToSend).subscribe((response) => {
      if (response) {
        this.loadingPayment.dismiss();
          console.log("success");
          // console.log(response);
          this.checkoutConfirm();
      }
    },
    error => {
      this.loadingPayment.dismiss();
      // console.log(error);
      this.checkoutError();
    });
    // .subscribe(data => {
    //   if (data) {
    //     var result = JSON.parse(data["_body"]);
    //     console.log(data);
    //   }
    //   this.checkoutConfirm();
    // });
  }

  async loadingSpinner() {
    this.loadingPayment = await this.loadingCtrl.create({
      spinner: 'crescent'
    }); 
  
    this.loadingPayment.present();
  }

  async checkoutConfirm() {
    // Perfom PayPal or Stripe checkout process
 
    let alert = await this.alertCtrl.create({
      header: 'Thanks for your Order!',
      message: 'We will deliver your order as soon as possible',
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.modalCtrl.dismiss('paid');
      this.cartService.clearCart();
    });
  }
  
  async checkoutError() {
    // Perfom PayPal or Stripe checkout process
 
    let alert = await this.alertCtrl.create({
      header: 'There was an error processing your transaction.',
      message: 'Your card has not been charged.  Please try again.',
      buttons: ['OK']
    });
    alert.present().then(() => {
      // this.modalCtrl.dismiss();
    });
  }
}